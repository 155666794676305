import { API_ENDPOINTS } from 'Constants/env';
import { ConfigurationResponse } from 'Interfaces/apiDtos';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import { action, observable, makeObservable } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';
import { bugsnagClient } from 'Utils/logUtils';
import API from '../api';
import { BaseStore } from './BaseStore';
import { RootStore } from './RootStore';

/*
    Because user config settings that come from the server needs to be used
    in so many places, and because they are mostly static but may need
    to be observed, we are allowing in this special case the ability
    to inject the ConfigStore into any component at any level. Note that
    this is against our standard practices.
*/

export class ConfigStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action
  clearAllData = () => {
    this.signedInPersonConfig = null;
  };

  @observable public signedInPersonConfig: IPromiseBasedObservable<
    AxiosResponseT<ConfigurationResponse>
  > = null;

  @action
  loadConfig = () => {
    bugsnagClient.leaveBreadcrumb('CUU2-2648 - Loading config');
    this.signedInPersonConfig = fromPromise(API.get(API_ENDPOINTS.Config));
    this.signedInPersonConfig.then(
      (data) => {
        bugsnagClient.leaveBreadcrumb(
          'CUU2-2648 - Load config successful',
          data
        );
      },
      (reason) => {
        bugsnagClient.leaveBreadcrumb('CUU2-2648 - Load config failed', reason);
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error loading Config'
        );
      }
    );
    return this.signedInPersonConfig;
  };
}
export default ConfigStore;
