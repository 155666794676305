import { ChatLayoutContext } from 'Components/Chat/contexts/ChatLayoutContext';
import { parseMessageDate } from 'Components/Chat/utils';
import { MessageBubble } from 'Components/shared/MessageBubble';
import { UserAvatar } from 'Components/shared/UserAvatar';
import { useScrollToBottom } from 'Hooks/scroll/useScrollToBottom';
import { toJS } from 'mobx';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';

import { useParams } from 'react-router';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { formatNumberWithNationalCode } from 'Utils/phoneUtil';
import { Styled } from './index.styles';

export const PinnedMessageView: React.FC = observer(() => {
  const {
    ui: {
      listOfPinnedMessages,
      selectPersonPresenceStatus,
      setMessageIdToScroll,
    },
    person,
  } = React.useContext<RootStoreProps>(MobXProviderContext);
  const { setView } = React.useContext(ChatLayoutContext);
  const { conversationId } = useParams();

  const { scrollRef } = useScrollToBottom();

  React.useEffect(() => {
    if (
      !listOfPinnedMessages
        ?.get(conversationId)
        ?.filter(
          ({ systemEvent, call, conference }) =>
            !(systemEvent?.eventType || call?.text || conference?.displayName)
        )?.length
    )
      setView('');
  }, [listOfPinnedMessages?.get(conversationId)]);

  const pinnedMessages = toJS(listOfPinnedMessages.get(conversationId));

  return (
    <Styled.PinnedMessages ref={scrollRef}>
      {pinnedMessages?.map((message) => {
        if (message.personId !== undefined && message.personId !== 0) {
          const personPbo = person.loadPersonByIdGetIfMissingGet(
            message.personId
          );
          if (personPbo !== undefined) {
            return personPbo?.case({
              fulfilled: ({ data }) =>
                message?.id && (
                  <MessageBubble
                    key={message.id}
                    AvatarComponent={
                      <UserAvatar
                        name={data.DisplayName}
                        presence={selectPersonPresenceStatus(message.personId)}
                      />
                    }
                    content={message.chat?.text || message.sms?.text || ''}
                    displayName={`${data?.firstName} ${data?.lastName}`}
                    isOwnMessage={person.loggedInPersonId === message.personId}
                    messageDate={parseMessageDate(message.created)}
                    onClick={() => {
                      setMessageIdToScroll(message.id);
                      setView('');
                    }}
                    {...{ message, conversationId }}
                  />
                ),
            });
          }
        }
        const contact = person.getExtrContactByPhoneNumber(message?.phone);

        return (
          <MessageBubble
            key={message.id}
            AvatarComponent={<UserAvatar />}
            messageDate={parseMessageDate(message.created)}
            isOwnMessage={contact && contact?.id === message.personId}
            content={message.chat?.text || message.sms?.text || ''}
            displayName={
              (contact && contact?.DisplayName()) ||
              formatNumberWithNationalCode(message.phone) ||
              formatNumberWithNationalCode(message?.sms?.fromPhone)
            }
            onClick={() => {
              setMessageIdToScroll(message.id);
              setView('');
            }}
            {...{ message, conversationId }}
          />
        );
      })}
    </Styled.PinnedMessages>
  );
});
