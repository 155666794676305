import * as localforage from 'localforage';
import { PersistStorage, StorageValue } from 'zustand/middleware';
import { CallSurveyActions, CallSurveyState } from './index.types';

/**
 * Serializes the call state before storage
 * @param {CallSurveyState} state - The call state to serialize
 * @returns {Object} Serialized state
 */
export function serializeCallState(
  state: CallSurveyState
): Partial<CallSurveyState> {
  return {
    personId: state.personId,
    callCount: state.callCount,
    callStartTime: state.callStartTime,
    callEndTime: state.callEndTime,
    currentCallId: state.currentCallId,
    callHistory: state.callHistory.map((call) => ({
      ...call,
      timestamp: call.timestamp,
    })),
    lastSurveyTimestamp: state.lastSurveyTimestamp,
    lastSurveyCallId: state.lastSurveyCallId,
  };
}

/**
 * Custom storage with type safety
 * @type {PersistStorage<CallSurveyState & CallSurveyActions>}
 */
export const createStorage: PersistStorage<
  CallSurveyState & CallSurveyActions
> = {
  getItem: async (name) => {
    try {
      const value = await localforage.getItem<StorageValue<CallSurveyState>>(
        name
      );

      if (!value) {
        return null;
      }

      const personId = name.split('-').pop();

      if (value.state) {
        value.state.personId = personId;
      }

      return value as StorageValue<CallSurveyState & CallSurveyActions>;
    } catch (error) {
      console.error('[Survey Store] Error getting state:', error);
      return null;
    }
  },
  setItem: async (name, value) => {
    try {
      if (name.includes('-1')) {
        console.warn(
          '[Survey Store] Skipping persistence for invalid person ID'
        );
        return;
      }

      if (value?.state) {
        const personId = name.split('-').pop();
        const serializedState = serializeCallState({
          ...value.state,
          personId,
        });

        const storageValue = {
          state: serializedState,
          version: value.version,
        };

        await localforage.setItem(name, storageValue);
      }
    } catch (error) {
      console.error('[Survey Store] Error setting state:', error);
    }
  },
  removeItem: async (name) => {
    try {
      await localforage.removeItem(name);
    } catch (error) {
      console.error('[Survey Store] Error removing state:', error);
    }
  },
};
