import type { RouteConfig } from './index.types';
import { CALENDAR_IDS, SHOW_CALENDAR } from 'Constants/env';
import { MainApp } from 'Containers/MainApp';
import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router';

import { RootStore } from 'Stores/RootStore';
import AddressBookRoutes from './addressBook/index.routes';
import CalendarRoutes from './calendar/index.routes';
import CallsRoutes from './calls/index.routes';
import ChatRoutes from './chat/index.routes';
import DirectoryRoutes from './directory/index.routes';
import EmailRoutes from './email/index.routes';
import FaxRoutes from './fax/index.routes';
import SettingsRoutes from './settings/index.routes';
import VideoAppRoutes from './videoApp/index.routes';
import VoicemailRoutes from './voicemail/index.routes';
import WelcomeRoutes from './welcome/index.routes';

/**
 * Checks if calendar feature should be enabled for the current user
 * @param {RootStore} rootStore - The root store instance
 * @returns {boolean} Whether calendar should be shown
 */
const isCalendarEnabled = (rootStore: RootStore): boolean => {
  const { loggedInPersonId } = rootStore.personStore;
  return (
    (SHOW_CALENDAR ||
      (CALENDAR_IDS as string[])?.includes(String(loggedInPersonId))) ??
    false
  );
};

/**
 * Creates and configures the application router with all routes
 * @param {RootStore} rootStore - The root store instance containing application state
 * @returns {ReturnType<typeof createBrowserRouter>} Configured router instance
 */
export const routes = (
  rootStore: RootStore
): ReturnType<typeof createBrowserRouter> => {
  const mainRouteConfig: RouteConfig = {
    path: '/',
    element: <MainApp rootStore={rootStore} />,
    children: [
      {
        index: true,
        element: <Navigate to="/chat" replace />,
      },
      VoicemailRoutes,
      FaxRoutes,
      WelcomeRoutes,
      EmailRoutes,
      DirectoryRoutes,
      AddressBookRoutes,
      ChatRoutes,
      CallsRoutes,
      VideoAppRoutes,
      SettingsRoutes,
    ],
  };

  if (isCalendarEnabled(rootStore)) {
    mainRouteConfig.children?.push(CalendarRoutes);
  }

  return createBrowserRouter([mainRouteConfig]);
};
