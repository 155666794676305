import { useAuth0 } from '@auth0/auth0-react';
import { AppError } from 'Components/AppError';
import { AppLoader } from 'Components/AppLoader';
import {
  DOWNLOAD_APP_URL_MAC,
  DOWNLOAD_APP_URL_WINDOWS,
  IS_ELECTRON,
  MIN_VERSION_FOR_UPDATE,
} from 'Constants/env';
import { AppWithErrorHandler } from 'Containers/App';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router';
import { Grid } from 'semantic-ui-react';
import {
  handleAuth0CodeReceived,
  sendIpcAuth0LoginUrl,
  focusAuth0Window,
  handleAuth0WindowClose,
} from 'Utils/ipcRendererEvents';
import { compareVersions } from 'Utils/semver';
import { extractVersions } from 'Utils/versionExtractor';
import { IMainApp } from './interfaces';

const downloadApp = () => {
  const macosPlatforms = [
    'Macintosh',
    'MacIntel',
    'MacPPC',
    'Mac68K',
    'darwin',
  ];
  const url = macosPlatforms.includes(window.navigator.platform)
    ? DOWNLOAD_APP_URL_MAC
    : DOWNLOAD_APP_URL_WINDOWS;
  window.open(url, '_blank');
};

const CenteredLayout = ({ children }: { children: React.ReactNode }) => (
  <Grid textAlign="center" verticalAlign="middle" className="login">
    <Grid.Column style={{ maxWidth: 450 }} className="login-container">
      {children}
    </Grid.Column>
  </Grid>
);

const UpdateRequiredMessage = () => (
  <>
    <p>
      The current version of the Communicator is outdated. Please update to the
      latest one:
    </p>
    <a href="javascript:void(0)" onClick={downloadApp}>
      Download here the latest version
    </a>
    <p className="app-notice-message">
      Your version of Communicator needs a manual update to continue working.
      Please download the new version to continue using it. If you need further
      help from our support colleagues, open a ticket here{' '}
      <a
        href="https://www.broadvoice.com/contact-us/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.broadvoice.com/contact-us/
      </a>
    </p>
  </>
);

const checkElectronUpdateRequired = () => {
  if (!IS_ELECTRON) return false;

  const installedVersion = extractVersions(navigator.userAgent).desktop.raw;

  return compareVersions(installedVersion, MIN_VERSION_FOR_UPDATE) === -1;
};

export const MainApp = ({ rootStore }: IMainApp) => {
  const auth0 = useAuth0();
  const ldClient = useLDClient();
  const { personStore } = rootStore;
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoadingElectron, setIsLoadingElectron] = useState<boolean>(false);
  const isAuth0WindowOpen = useRef<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    handleAuth0WindowClose(() => {
      isAuth0WindowOpen.current = false;
    });
  }, []);

  useEffect(() => {
    personStore.setAuth0(auth0);
    personStore.setLDClient(ldClient);
  }, [auth0, ldClient, personStore]);

  const loginWithToken = useCallback(async () => {
    try {
      const token = await personStore.exchangeForBVToken({
        redirect_uri: window.location.href,
      });
      await personStore.loginWithExistingToken(token);
    } catch (e) {
      setError(e.response.data.error);
    } finally {
      setLoading(false);
    }
  }, [personStore]);
  const loginElectron = useCallback(async () => {
    try {
      if (isAuth0WindowOpen.current) {
        focusAuth0Window();
        return;
      }
      isAuth0WindowOpen.current = true;

      const auth0LoginUrl = await auth0.buildAuthorizeUrl({
        prompt: 'login',
        max_age: 0,
        sso: false,
        rememberLastLogin: false,
      });

      handleAuth0CodeReceived(async (code, url) => {
        try {
          setIsLoadingElectron(false);
          await auth0.handleRedirectCallback(decodeURIComponent(url));
          await loginWithToken();
        } catch (error) {
          console.error(error);
        }
      });
      sendIpcAuth0LoginUrl(auth0LoginUrl);
      setIsLoadingElectron(true);
    } catch (error) {
      setIsLoadingElectron(false);
      isAuth0WindowOpen.current = false;
    }
  }, [auth0, loginWithToken]);

  useEffect(() => {
    //short circuit if update is required, do not prompt user to login
    if (checkElectronUpdateRequired()) {
      return;
    }

    if (auth0.isLoading) {
      return;
    }

    const urlParams = new URLSearchParams(location.search);
    const state = urlParams.get('state');

    void (async () => {
      if (
        !auth0.isAuthenticated &&
        !(state === 'calendars' || state === 'contacts')
      ) {
        IS_ELECTRON ? await loginElectron() : await auth0.loginWithRedirect();
        return;
      }

      await loginWithToken();
    })();
  }, [auth0, auth0.isLoading, loginElectron, loginWithToken]);

  if (IS_ELECTRON && checkElectronUpdateRequired()) {
    return (
      <CenteredLayout>
        <UpdateRequiredMessage />
      </CenteredLayout>
    );
  }

  const appIsStillLoading = () =>
    auth0.isLoading || !auth0.user || loading || personStore.isLoggingOut;

  if (appIsStillLoading() && isLoadingElectron) {
    return (
      <CenteredLayout>
        <button
          className="ui button"
          onClick={() => void loginElectron()}
          data-testid="electron-login-button"
        >
          Login
        </button>
      </CenteredLayout>
    );
  }

  if (appIsStillLoading()) {
    return <AppLoader />;
  }

  return error ? (
    <AppError error={error} />
  ) : (
    <AppWithErrorHandler
      ui={rootStore.uiStore}
      person={rootStore.personStore}
      message={rootStore.messageStore}
      phoneStore={rootStore.phoneStore}
    />
  );
};
