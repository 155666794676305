import { CALL_STATE } from 'Constants/enums';
import { STORE_CONFIG } from 'Constants/stores';
import withRouter from 'Hocs/WithRouter';
import { isEmpty } from 'lodash';
import { MobXProviderContext, inject, observer } from 'mobx-react';
import * as React from 'react';

import { useNavigate } from 'react-router';
import { RootStoreProps } from 'Stores/RootStore.types';
import { isNullOrUndefined } from 'util';
import { formatNumber, formatNumberWithNationalCode } from 'Utils/phoneUtil';

import { useEntityActions } from '~/containers/EntityProfile/index.hooks';
import ContactInfo from './ContactInfo';
import EntityActionButtons from './EntityActionButtons';
import ExternalContactAlias from './ExternalContactAlias';
import { EntityInfoComponentProps } from './interfaces';

const EntityProfileInfo: React.FC<EntityInfoComponentProps> = ({
  contact,
  extrContacts,
  person,
}) => {
  const navigate = useNavigate();
  const { messageUser, makeCall } = useEntityActions();

  const {
    person: {
      loggedInPersonId,
      setEditContact,
      personAvaliableFeatures: { video: loggedInPersonVideoFeature },
      allContactByPhone,
      selectPersonValueById,
    },
    conversation: {
      loadOrCreateConversationWithPost,
      postConferenceByConversationId,
      channelInfoDetails,
    },
    ui: { setOpenedRightSidebarsOrder },
    phoneCall: { phoneCalls },
  } = React.useContext(MobXProviderContext) as RootStoreProps;

  const callDefaultUserLine = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpenedRightSidebarsOrder('dial-pad');
    if (person) {
      makeCall(Number.parseInt(person.id), null);
    } else if (extrContacts) {
      makeCall(null, extrContacts[0]?.phoneNumbers[0].number);
    } else {
      const primaryLine = contact.phoneNumber;
      if (!isEmpty(primaryLine)) {
        makeCall(null, primaryLine);
      } else {
        throw new Error('Contact Phone Number is Empty');
      }
    }
  };

  const setDataLayer = (conversationIdFromUrl: string) => {
    if (window['dataLayer']) {
      (window['dataLayer'] as any[]).push({
        event: 'placeVideoConference',
        conversationId: conversationIdFromUrl,
      });
    }
  };

  const placeVideoCallConference = (personId: string): void => {
    const conversationIdFromUrl = selectPersonValueById(
      Number.parseInt(personId)
    ).data.personalConferenceUrl;
    const isDefault = channelInfoDetails.channelConversationId === '0';
    if (isDefault && conversationIdFromUrl) {
      const conversationId = isDefault
        ? conversationIdFromUrl.split('-').pop()
        : channelInfoDetails.channelConversationId;
      void postConferenceByConversationId(conversationId);
      setDataLayer(conversationId);
    } else if (personId) {
      void loadOrCreateConversationWithPost(personId).then((resp) => {
        const conversationId = resp.data.id.toString();
        void postConferenceByConversationId(conversationId);
        setDataLayer(conversationId);
      });
    }
  };

  const onMessageUserClick = () => {
    if (!isEmpty(person)) {
      messageUser(person.id, null, person.contactType);
    } else if (extrContacts) {
      messageUser(null, extrContacts[0]?.phoneNumbers[0].number, null);
    } else {
      messageUser(null, contact.FormattedPhone, contact.contactType);
    }
  };

  const getContact = (id: number, phoneNums: any[]) => {
    const phoneNum = phoneNums && phoneNums[0]?.number;
    const formatedPhoneNum = formatNumberWithNationalCode(phoneNum);
    const contactFromPhoneList =
      phoneNum &&
      allContactByPhone.has(formatedPhoneNum) &&
      allContactByPhone.get(formatedPhoneNum);
    const contactFromAllContact =
      !contactFromPhoneList &&
      extrContacts?.find((contact) => contact.id === id);
    return contactFromPhoneList || contactFromAllContact;
  };

  const editExtContacts =
    (id: number, phoneNumbers: string[]) => (e: React.MouseEvent) => {
      e.stopPropagation();
      const contact = getContact(id, phoneNumbers);
      setEditContact(contact);
      navigate('/addressBook/contact', { state: { from: '/chat' } });
    };

  const commProfile = React.useMemo(
    () =>
      !isNullOrUndefined(person) &&
      person.id?.toString() !== loggedInPersonId?.toString(),
    [person, loggedInPersonId]
  );

  const mobileProfile = React.useMemo(
    () => isNullOrUndefined(person) && !isNullOrUndefined(contact),
    [person, contact]
  );

  let callState = CALL_STATE.Available;
  phoneCalls.filter((p) => {
    if (!isEmpty(person)) {
      const elementPR = p.callUri?.split('@')[0]?.substring(2);
      if (elementPR === person.id.toString()) {
        callState = p.isCallConnecting
          ? CALL_STATE.Connecting
          : CALL_STATE.Connected;
      }
    } else if (
      formatNumber(contact?.phoneNumber) ===
      formatNumber(p.callUri?.split('@')[0])
    ) {
      callState = p.isCallConnecting
        ? CALL_STATE.Connecting
        : CALL_STATE.Connected;
    }
  });

  const isVideoCallDisabled = React.useMemo(
    () =>
      !loggedInPersonVideoFeature.enabled ||
      contact?.phoneNumber ||
      extrContacts?.[0]?.phoneNumbers?.[0]?.number,
    [contact?.phoneNumber, extrContacts, loggedInPersonVideoFeature.enabled]
  ) as boolean;

  return (
    <div>
      <ContactInfo
        extrContacts={extrContacts}
        person={person}
        placeVideoCallConference={placeVideoCallConference}
      />

      {(commProfile || mobileProfile || extrContacts) && (
        <>
          <EntityActionButtons
            isVideoCallDisabled={isVideoCallDisabled}
            placeVideoCallConference={placeVideoCallConference}
            person={person}
            callDefaultUserLine={callDefaultUserLine}
            callState={callState}
            onMessageUserClick={onMessageUserClick}
          />
        </>
      )}
      {extrContacts?.length > 1 && (
        <ExternalContactAlias
          extrContacts={extrContacts}
          editExtContacts={editExtContacts}
        />
      )}
    </div>
  );
};

export default inject(STORE_CONFIG)(withRouter(observer(EntityProfileInfo)));
