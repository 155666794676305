
import { CalendarComponent } from 'Components/Calendar';
import { SourcesAddressBook } from 'Components/SourcesAddressBook';
import { CALENDAR_SUBPATHS } from 'Constants/routing';
import { CalendarContainer } from 'Containers/CalendarContainer';
import React from 'react';
import { Navigate, RouteObject } from 'react-router';

const calendarRoutes: RouteObject = {
  path: 'calendar/*',
  element: <CalendarContainer />,
  handle: { title: 'Calendar' },
  children: [
    { path: 'events', element: <CalendarComponent testId='calendarContainer' /> },
    {
      path: 'calendars',
      element: <SourcesAddressBook titleFor="calendars" testId='calendarContainer' />,
    },
    { path: '*', element: <Navigate to={CALENDAR_SUBPATHS.EVENTS} replace /> },
  ],
};

export default calendarRoutes;
