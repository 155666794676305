import { PHONE_INCOMING_128, PHONE_INCOMING_128_S3 } from 'Constants/assets';
import {
  IS_ELECTRON,
  NODE_ENV_PRODUCTION,
  NODE_ENV_TEST,
  SIP_USER_AGENT,
} from 'Constants/env';
import {
  SELECTED_MICROPHONE,
  SELECTED_RING_DEVICE,
  SELECTED_SPEAKER,
  SHOW_INCOMING_CALL,
} from 'Constants/localstorage';
import { WN_INCOMING_CALL_PREFIX } from 'Constants/webNotifications';
import {
  AxiosResponseT,
  ResultsCollectionObservable,
} from 'Interfaces/axiosResponse';
import { IMicrophoneOption } from 'Interfaces/phoneStore';
import localforage from 'localforage';
import { get, isEmpty } from 'lodash';
import {
  action,
  computed,
  IObservableArray,
  observable,
  runInAction,
  when,
  makeObservable,
} from 'mobx';
import { IPromiseBasedObservable, createTransformer } from 'mobx-utils';
import { ContactModel } from 'Models/ContactModel';
import { useNotificationStore } from 'Modules/notification/store/notification';
import moment from 'moment-timezone';
import {
  Inviter,
  Registerer,
  RegistererState,
  Session,
  Transport,
  UserAgent,
  UserAgentOptions,
  URI,
} from 'sip.js';
import { BaseStore } from 'Stores/BaseStore';
import { RootStore } from 'Stores/RootStore';
import { isNullOrUndefined } from 'util';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { bugsnagClient } from 'Utils/logUtils';
import { logError } from 'Utils/phoneLogUtils';
import PhoneNumberUtil, {
  formatNumberNoPlusIfUS,
  formatNumberWithNationalCode,
  isPhoneNumber,
} from 'Utils/phoneUtil';
import { searchPersonBySip } from 'Utils/searchPersonBySip';
import { stopMediaStream } from 'Utils/streamUtils';
import { v4 as uuid } from 'uuid';
import { MessageModel, PersonModel, PhoneCallModel } from '../models';

interface IHTMLAudioElement extends HTMLAudioElement {
  setSinkId?: (sinkId: number | string) => Promise<any>;
}

export class PhoneStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
    this.runRegister();
  }

  public ringtonePlayPromise: Promise<void> = null;

  @observable
  public outBoundCallPutStatus: IPromiseBasedObservable<
    AxiosResponseT<ResultsCollectionObservable<MessageModel>>
  > = null;

  @observable
  public putOutboundCall: IPromiseBasedObservable<
    AxiosResponseT<ResultsCollectionObservable<MessageModel>>
  > = null;

  @observable
  public inBoundCallPutStatus: IPromiseBasedObservable<
    AxiosResponseT<ResultsCollectionObservable<MessageModel>>
  > = null;

  @observable
  public putInboundCall: IPromiseBasedObservable<
    AxiosResponseT<ResultsCollectionObservable<MessageModel>>
  > = null;

  @observable
  public localSipSession: Session;

  @observable
  public localRemoteAudio: IHTMLAudioElement;

  @action
  public setLocalRemoteAudio = (value) => (this.localRemoteAudio = value);

  @observable
  public localRingTone: IHTMLAudioElement;

  @action public setLocalRingTone = (value) => (this.localRingTone = value);

  @observable
  public localIncomingTone: IHTMLAudioElement;

  @action public setLocalIncomingTone = (value) =>
    (this.localIncomingTone = value);

  @observable
  public outBound = false;

  @observable
  public secondLocalIncomingtone: IHTMLAudioElement;

  @action public setSecondLocalIncomingtone = (value) =>
    (this.secondLocalIncomingtone = value);

  @observable
  public isWebRTCReconnecting = false;

  @action
  public setIsWebRTCReconnecting = (isReconnecting: boolean) =>
    (this.isWebRTCReconnecting = isReconnecting);

  public webRTCTimeOut;

  @observable
  public isWebRTCConnected = false;

  @action
  public setIsWebRTCConnected = (isWebRTCConnected: boolean) =>
    (this.isWebRTCConnected = isWebRTCConnected);

  @observable
  public webRTCTimer = 6;

  @action
  public setWebRTCTimer = (webRTCTimer: number) =>
    (this.webRTCTimer = webRTCTimer);

  @observable
  public webrtcCounter = 0;

  @action
  public setWebrtcCounter = (counter: number) => (this.webrtcCounter = counter);

  @observable
  speakerId: string;

  @observable
  ringtoneSpeakerId: string;

  @observable
  microphoneId: string;

  @observable
  theDefaultMic = 'default';

  @action
  setTheDefaultMic = (theDefaultMic: string) => {
    this.theDefaultMic = theDefaultMic;
  };

  @observable
  theDefaultSpeaker = 'default';

  @action
  setTheDefaultSpeaker = (theDefaultSpeaker: string) => {
    this.theDefaultSpeaker = theDefaultSpeaker;
  };

  @observable
  theDefaultRingDevice = 'default';

  @action
  setTheDefaultRingDevice = (theDefaultSpeaker: string) => {
    this.theDefaultRingDevice = theDefaultSpeaker;
  };

  @observable
  microphoneOptions = observable.array<IMicrophoneOption>();

  @action
  pushMicrophoneOptions = (microphoneOptions: IMicrophoneOption) => {
    this.microphoneOptions.push(microphoneOptions);
  };

  @observable
  speakerOptions = observable.array<IMicrophoneOption>();

  @action
  pushSpeakerOptions = (speakerOptions: IMicrophoneOption) => {
    this.speakerOptions.push(speakerOptions);
  };

  listOfMicroPhones = observable.map<string, boolean>();

  listOfSpeakers = observable.map<string, boolean>();

  isTheNewMicDeviceInList = createTransformer(
    (microphoneId: string) => !this.listOfMicroPhones.has(microphoneId)
  );

  isTheNewSpeakerDeviceInList = createTransformer(
    (speakers: string) => !this.listOfSpeakers.has(speakers)
  );

  @action
  checkForIODevices = () => {
    let keyCounter = 0;
    if (this.browserMicrophonePermissionState !== 'denied') {
      //@ts-ignore
      if (!window.localStream3?.active) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(
          (resp) => {
            //@ts-ignore
            window.localStream3 = resp;
            navigator.mediaDevices.enumerateDevices().then(
              (devices) => {
                runInAction(() => {
                  this.microphoneOptions.clear();
                  this.speakerOptions.clear();
                });
                for (const device of devices) {
                  keyCounter++;
                  if (device.kind === 'audioinput') {
                    this.pushMicrophoneOptions({
                      key: keyCounter,
                      text: device.label,
                      value: device.deviceId,
                    });
                    localforage.getItem<string>(SELECTED_MICROPHONE).then(
                      (mc) => {
                        if (
                          !isNullOrUndefined(mc) &&
                          devices.find((device) => device.deviceId === mc)
                        ) {
                          this.setTheDefaultMic(mc);
                          this.configureMicrophone(mc);
                        } else {
                          if (this.isTheNewMicDeviceInList(device.deviceId)) {
                            this.setTheDefaultMic(device.deviceId);
                            this.configureMicrophone(device.deviceId);
                            return;
                          }
                          this.setTheDefaultMic('default');
                          this.configureMicrophone('default');
                        }
                      },
                      (err) =>
                        logError(
                          err,
                          err.message,
                          'PhoneStore',
                          'Selecting microphone from localForage'
                        )
                    );
                  } else if (device.kind === 'audiooutput') {
                    this.pushSpeakerOptions({
                      key: keyCounter,
                      text: device.label,
                      value: device.deviceId,
                    });
                    localforage.getItem<string>(SELECTED_SPEAKER).then(
                      (speaker) => {
                        if (
                          !isNullOrUndefined(speaker) &&
                          devices.find((device) => device.deviceId === speaker)
                        ) {
                          this.setTheDefaultSpeaker(speaker);
                          this.configureSpeaker(speaker);
                        } else {
                          if (
                            this.isTheNewSpeakerDeviceInList(device.deviceId)
                          ) {
                            this.setTheDefaultSpeaker(device.deviceId);
                            this.configureMicrophone(device.deviceId);
                            return;
                          }
                          this.setTheDefaultSpeaker('default');
                          this.configureSpeaker('default');
                        }
                      },
                      (err) =>
                        logError(
                          err,
                          err.message,
                          'PhoneStore',
                          'Selecting speaker device from localForage'
                        )
                    );
                    localforage.getItem<string>(SELECTED_RING_DEVICE).then(
                      (speaker) => {
                        if (
                          !isNullOrUndefined(speaker) &&
                          devices.find((device) => device.deviceId === speaker)
                        ) {
                          this.setTheDefaultRingDevice(speaker);
                          this.configureRingTone(speaker);
                        } else {
                          this.setTheDefaultRingDevice('default');
                          this.configureRingTone('default');
                        }
                      },
                      (err) =>
                        logError(
                          err,
                          err.message,
                          'PhoneStore',
                          'Selecting ring device from localForage'
                        )
                    );
                  }
                }
                this.setIsThereDeviceChange(false);
              },
              (err) =>
                logError(err, err.message, 'PhoneStore', 'enumerateDevices')
            );
            //@ts-ignore
            stopMediaStream(window.localStream3);
          },
          (err) => logError(err, err.message, 'PhoneStore', 'getUserMedia')
        );
      }
    }
  };

  /**
   * For Any information regarding WEBRTC follow https://webrtc.github.io/samples/
   */
  @action
  configureMicrophone = (microphoneId?: string) => {
    this.microphoneId = microphoneId;
    this.listOfMicroPhones.set(microphoneId, true);
    this.phoneCalls.forEach(this.setUserMediaInputTracks);
  };

  private setUserMediaInputTracks = (phoneCall: PhoneCallModel) => {
    //@ts-ignore
    const pc = phoneCall.session.sessionDescriptionHandler?.peerConnection;
    //@ts-ignore
    const stream: MediaStream = window.localStream;
    if (stream) {
      stream.getAudioTracks().forEach((tracks) => {
        if (
          tracks.id === this.microphoneId ||
          tracks.label.toLowerCase().includes(this.microphoneId)
        ) {
          const sender = pc.getSenders()[0];
          const peerConnectionTrack = sender?.track;

          //replace in case senderTrack is different than required one
          if (
            peerConnectionTrack &&
            !peerConnectionTrack?.label
              .toLowerCase()
              .includes(this.microphoneId) &&
            peerConnectionTrack?.id !== this.microphoneId
          ) {
            sender?.replaceTrack(tracks);
          }
        }
      });
    }
  };

  /**
   * Regarding setSinkId https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId
   */

  @action
  setTheMic = (e: any, option: IMicrophoneOption) => {
    const { phoneStore } = this.rootStore;
    phoneStore.configureMicrophone(option.value);
    phoneStore.setTheDefaultMic(option.value);
    localforage.setItem<string>(SELECTED_MICROPHONE, option.value);
    pushToGTMDataLayer('setMicrophone');
  };

  @action
  setTheSpeaker = (e: any, option: IMicrophoneOption) => {
    const { phoneStore } = this.rootStore;
    phoneStore.configureSpeaker(option.value);
    phoneStore.setTheDefaultSpeaker(option.value);
    localforage.setItem<string>(SELECTED_SPEAKER, option.value);
    pushToGTMDataLayer('setSpeaker');
  };

  @action
  checkSetDevice = (typeOfDevice) => {
    const { phoneStore } = this.rootStore;
    localforage.getItem<string>(typeOfDevice).then((device) => {
      if (isNullOrUndefined(device)) {
        phoneStore.setIsThereDeviceChange(false);
        phoneStore.checkForIODevices();
      }
    });
  };

  @action
  setRingSpeaker = (e: any, option: IMicrophoneOption) => {
    const { phoneStore } = this.rootStore;
    phoneStore.configureRingTone(option.value);
    phoneStore.setTheDefaultRingDevice(option.value);
    localforage.setItem<string>(SELECTED_RING_DEVICE, option.value);
    pushToGTMDataLayer('setRingtoneDevice');
  };

  @action
  configureSpeaker = (speakerId?: string) => {
    this.speakerId = speakerId;
    if (!isNullOrUndefined(speakerId)) {
      this.listOfSpeakers.set(speakerId, true);
    }
    if (!isNullOrUndefined(speakerId)) {
      const noCalls = this.phoneCalls?.length === 0;
      if (noCalls && this.localRemoteAudio?.srcObject) {
        this.localRemoteAudio.srcObject = null;
      }

      // @ts-ignore
      this.localRemoteAudio
        ?.setSinkId?.(speakerId)
        .then(() => {
          console.debug(
            `Success, audio output device attached: ${speakerId} to element with ${this.localRemoteAudio.title} as source.`
          );
        })
        .catch((error) => {
          console.error(
            `Failed, Sound Notification output device attached to element with ${this.localRemoteAudio?.title} as source.`,
            error
          );
          bugsnagClient.notify(error, (event) => {
            event.severity = 'error';
            event.context = 'configureSpeaker';
          });
        });
    }
  };

  addDeviceToAudio = (audioElem: IHTMLAudioElement, speakerId: string) => {
    try {
      // @ts-ignore
      audioElem &&
        audioElem.setSinkId(speakerId).then(() => {
          console.debug(
            `Success, OutGoing RingTone output device attached: ${speakerId} to element with ${audioElem.title} as source.`
          );
        });
    } catch (error) {
      console.debug(
        `Failed, Sound Notification output device attached to element with ${audioElem?.title} as source.`
      );
    }
  };

  @action
  configureRingTone = (speakerId: string) => {
    this.ringtoneSpeakerId = speakerId;
    if (!isNullOrUndefined(speakerId)) {
      this.listOfSpeakers.set(speakerId, true);
    }

    if (!isNullOrUndefined(speakerId)) {
      this.addDeviceToAudio(this.localRingTone, speakerId);
      this.addDeviceToAudio(this.localIncomingTone, speakerId);
      this.addDeviceToAudio(this.secondLocalIncomingtone, speakerId);
    }
  };

  /**
   * Keeping the new Answered call unHold and have all other calls onHold
   */
  @action
  public setLocalSession = (localSipSession: Session) => {
    this.localSipSession = localSipSession;
  };

  @observable
  browserMicrophonePermissionState: null | 'granted' | 'denied' = null;

  @action
  setBrowserMicrophonePermissionState = (state: 'granted' | 'denied') => {
    this.browserMicrophonePermissionState = state;
  };

  public conferenceRoomsIdAndNumbers = observable.map<string, string[]>();

  @observable
  public existingConferenceId?: string;

  @action
  public setExistingConferenceIdId = (existingConferenceId: string) =>
    (this.existingConferenceId = existingConferenceId);

  // controls mic enable setting on settings panel, not actual permission, though this will cause a permission to be asked for, nor microphone/phonemute
  @observable
  public testMicrophoneEnabled = false;

  @action
  toggleTestMicrophoneEnabled = () => {
    this.testMicrophoneEnabled = !this.testMicrophoneEnabled;
  };

  @action
  clearAllData = async () => {
    try {
      this.phoneCalls.forEach((pc) => {
        // only runs this if the users had made any call or recieved any call
        if (
          pc.session &&
          (pc.session.state === 'Established' ||
            pc.session.state === 'Establishing')
        ) {
          pc.session.bye();
        }

        if (pc.isCallConnecting) {
          pc.clearKeyPress();
          pc.setShowDialPad(false);
          pc.muteIconState('microphone');
          pc.setIsCallMuted(false);
          pc.onHoldIconState('pause circle outline');
          pc.setIsCallOnHold(false);
          pc.setIsMediaConnecting(false);
          pc.setIsCallConnected(false);
          pc.setIsCallConnecting(false);
          pc.setIsMediaConnected(false);
          pc.ringtone.pause();
          pc.ringtone.currentTime = 0;
          pc.clearPersonAndContact();
          pc.resetDisplay();
          pc.session.bye();
        }
        // unregistering Sip of the user

        pc.clearPersonAndContact();
      });
    } catch (error) {
      console.error(error);
      logError(
        error,
        'Unkown issue, hard reload happening',
        'PhoneStore',
        'clearAllData'
      );
      // If nothing works, Hard Reload
      location.reload();
    }
    if (this.ua?.isConnected()) await this.ua.stop();

    if (this.registerer?.state === RegistererState.Registered)
      await this.registerer.unregister();

    // wont register back unless the person logs In again
    //TODO if this is still needed
    when(
      () =>
        !this.rootStore.personStore.IsLoggedIn &&
        this.rootStore.configStore.signedInPersonConfig === null,
      () => {
        //  we may no longer need this, but I will leave it here for now. and checkt he behaviour
        //this.runRegister();
      }
    );
  };

  @observable
  public sessionUIs: {};

  @observable
  public phoneCalls: IObservableArray<PhoneCallModel> = observable.array();

  @action
  public setPhoneCalls = (phoneCallModel: PhoneCallModel) => {
    this.phoneCalls.push(phoneCallModel);
  };

  @observable
  public incomingPhoneCalls: IObservableArray<PhoneCallModel> =
    observable.array();

  @action
  public setIncomingCalls = (phoneCallModel: PhoneCallModel) => {
    this.incomingPhoneCalls.push(phoneCallModel);
  };

  @observable
  public warmTransferOld: PhoneCallModel;

  @action
  public setWarmTransferOld = (warmTransferOld: PhoneCallModel) => {
    this.warmTransferOld = warmTransferOld;
  };

  private ua: UserAgent;
  private sipTransport: Transport;

  @observable
  private maxReconnectionAttempts = 6;

  @action
  setMaxReconnectionAttempts = (maxReconnectionAttempts: number) =>
    (this.maxReconnectionAttempts = maxReconnectionAttempts);

  @action
  public addingMaxReconnectionAttempts = () => {
    this.setMaxReconnectionAttempts(this.maxReconnectionAttempts + 1);
  };

  public reconnectTimer;

  @observable
  isThereDeviceChange = false;

  @action
  setIsThereDeviceChange = (isThereDeviceChange: boolean) => {
    this.isThereDeviceChange = isThereDeviceChange;
  };

  @observable
  registerer: Registerer = null;

  handleRegisterSip = () => {
    this.registerer = new Registerer(this.ua);
    // Setup registerer state change handler
    this.registerer.stateChange.addListener((newState) => {
      switch (newState) {
        case RegistererState.Terminated:
          this.setIsWebRTCConnected(false);
          break;
      }
    });
  };

  private getPersonByRemoteIdentityUser = async (
    user: string,
    host: string
  ): Promise<PersonModel | null> => {
    if (isPhoneNumber(user)) return null;

    // else if user is ext. username can be searched in BV directory
    return searchPersonBySip(
      `${user}@${host}`,
      this.rootStore.notificationStore
    );
  };

  /**
   * runRegister does two things
   * 1) whenever the user login it registered with the SIP in the server and whenever Logs out it unregisters the user
   * 2) creates invite for incoming calls
   * SIP invite uses the `session.request` from the header that is sent by FreeSwitch
   */
  @action
  public runRegister() {
    when(
      () =>
        this.rootStore.personStore.IsLoggedIn &&
        this.rootStore.configStore.signedInPersonConfig !== null &&
        this.rootStore.configStore.signedInPersonConfig.state === 'fulfilled',
      async () => {
        // We don't want to cancel active/incoming calls
        if (this.AnyPhoneConnectionActive) {
          return;
        }

        if (this.ua?.isConnected()) await this.ua.stop();

        if (this.registerer?.state === RegistererState.Registered)
          await this.registerer.unregister();

        const loadCfgStatus = this.rootStore.configStore.signedInPersonConfig;
        if (loadCfgStatus.state === 'fulfilled') {
          const webRtcConfig = loadCfgStatus.value.data.webRtc;
          if (!NODE_ENV_PRODUCTION) {
            webRtcConfig.traceSip = true;
          }
          const loggedInPerson =
            this.rootStore.personStore.selectPersonValueById(
              this.rootStore.personStore.loggedInPersonId
            );
          let loggedInCallerId: string;
          const lpLines = get(loggedInPerson, 'data.lines', []);
          for (let i = 0; i < lpLines.length; i++) {
            if (lpLines[i].id === loggedInPerson.data.primaryLineId) {
              if (lpLines[i].callerId) {
                loggedInCallerId = lpLines[i].callerId;
              } else {
                const plId = get(loggedInPerson, 'data.primaryLineId', '');
                loggedInCallerId = plId.toString();
              }
            }
          }
          if (isEmpty(loggedInCallerId)) {
            loggedInCallerId = 'Unknown Number';
          }
          const url = webRtcConfig.uri?.replace(/\s/g, '');
          const uri: URI = UserAgent.makeURI(
            webRtcConfig.uri?.includes('sip:') ? url : 'sip:' + url
          );
          if (!uri) {
            this.rootStore.notificationStore.addAxiosErrorNotification(
              null,
              'URI for making sip connection is wrong, please contact the support'
            );
            return;
          }
          const configuration: UserAgentOptions = {
            uri: uri,
            authorizationUsername: webRtcConfig.authorizationUser,
            authorizationPassword: webRtcConfig.password,
            logBuiltinEnabled: !NODE_ENV_PRODUCTION && !NODE_ENV_TEST,
            logLevel: NODE_ENV_PRODUCTION ? 'warn' : 'debug',
            transportOptions: {
              connectionTimeout: 9,
              traceSip: !NODE_ENV_PRODUCTION && !NODE_ENV_TEST,
              wsServers: webRtcConfig.wsServers,
              maxReconnectionAttempts: 6,
              reconnectionTimeout: 9,
            },
            sessionDescriptionHandlerFactoryOptions: {
              constraints: {
                audio: true, // {deviceId: this.microphoneId ? {exact: this.microphoneId} : 'default'}
                video: false,
              },
              iceCheckingTimeout: 5000,
              rtcConfiguration: {
                rtcpMuxPolicy: 'require',
                // iceServers: [
                //     {
                //         urls: '206.15.174.53:63478'
                //     }
                // ]
              },
            },
            userAgentString: SIP_USER_AGENT,
            delegate: {
              onInvite: (invitation) => {
                (async () => {
                  const presenceStatus =
                    this.rootStore.uiStore.selectPersonPresenceStatus(
                      this.rootStore.personStore.loggedInPersonId
                    ).state;
                  let loggedInCallerId: string;
                  for (let i = 0; i < loggedInPerson.data.lines.length; i++) {
                    if (
                      loggedInPerson.data.lines[i].id ===
                      loggedInPerson.data.primaryLineId
                    ) {
                      if (loggedInPerson.data.lines[i].callerId) {
                        loggedInCallerId =
                          loggedInPerson.data.lines[i].callerId;
                      } else {
                        loggedInCallerId =
                          loggedInPerson.data.primaryLineId.toString();
                      }
                    }
                  }
                  if (isEmpty(loggedInCallerId)) {
                    loggedInCallerId = 'Unknown Number';
                  }
                  let phoneNumber = '';

                  const newCall = new PhoneCallModel(
                    this.rootStore,
                    this,
                    loggedInCallerId,
                    this.rootStore.personStore.loggedInAccountId,
                    this.rootStore.personStore.loggedInPersonId,
                    invitation,
                    null,
                    null,
                    null,
                    this.localRemoteAudio,
                    this.localRingTone,
                    this.localIncomingTone,
                    this.secondLocalIncomingtone
                  );

                  let fromPerson = await this.getPersonByRemoteIdentityUser(
                    invitation.remoteIdentity.uri.user,
                    invitation.remoteIdentity.uri.host
                  );

                  if (
                    !fromPerson &&
                    isPhoneNumber(invitation.remoteIdentity.uri.user)
                  ) {
                    fromPerson =
                      await this.rootStore.personStore.getFromDirectory(
                        formatNumberNoPlusIfUS(
                          invitation.remoteIdentity.uri.user
                        )
                      );
                  }

                  if (fromPerson) {
                    const callerIdName = invitation.remoteIdentity.displayName;
                    const personId = fromPerson.id;

                    if (callerIdName) {
                      newCall.setCallerIdName(callerIdName);
                    }
                    if (personId !== undefined) {
                      const personIdNumber = parseInt(personId, 10);
                      const phoneUri = `pr${personId}@${invitation.remoteIdentity.uri.host}`;
                      newCall.setPerson(
                        this.rootStore.personStore.loadPersonByIdGetIfMissingGet(
                          personIdNumber
                        )
                      );
                      if (
                        this.enableIncomingCalls &&
                        newCall.person &&
                        presenceStatus !== 'DoNotDisturb'
                      ) {
                        newCall.person.then((p) => {
                          this.addIncomingCallWebNotification(
                            p.data.lines?.length > 0
                              ? formatNumberWithNationalCode(
                                  p.data.lines[0].callerId
                                )
                              : '',
                            p.data.DisplayName
                          );
                        });
                      }

                      const uri = UserAgent.makeURI(
                        'sip:' + phoneUri.replace(/\s/g, '')
                      );
                      newCall.setPhoneURI(uri);
                    }
                  } else {
                    // Incoming Calls only
                    const callerIdName =
                      invitation.request.from.displayName || '';

                    if (callerIdName) {
                      newCall.setCallerIdName(callerIdName);
                    }
                    if (invitation.remoteIdentity.uri instanceof URI) {
                      phoneNumber = invitation.remoteIdentity.uri.user;
                      if (!isNaN(phoneNumber as any)) {
                        const contactIfPhoneNumberMissing =
                          this.rootStore.contactStore.loadContactByPhoneNumber(
                            phoneNumber
                          );
                        newCall.setContact(contactIfPhoneNumberMissing);

                        const phoneNumNoPlus =
                          formatNumberNoPlusIfUS(phoneNumber);
                        const externalContact =
                          this.rootStore.personStore.getExtrContactByPhoneNumber(
                            phoneNumNoPlus
                          );

                        if (
                          this.enableIncomingCalls &&
                          newCall.contact &&
                          presenceStatus !== 'DoNotDisturb'
                        ) {
                          newCall.contact.then((c) => {
                            this.addIncomingCallWebNotification(
                              phoneNumber,
                              externalContact?.DisplayName() ||
                                c?.data?.DisplayName ||
                                callerIdName
                            );
                          });
                        }
                      }
                      newCall.setPhoneNumber(phoneNumber);
                      const uri = invitation.remoteIdentity.uri.host?.includes(
                        'sip:'
                      )
                        ? invitation.remoteIdentity.uri.host
                        : `sip:${phoneNumber}@${invitation.remoteIdentity.uri.host}`;
                      const phoneNumUri = UserAgent.makeURI(
                        `sip:${uri.replace(/\s/g, '')}`
                      );
                      newCall.setPhoneURI(phoneNumUri);
                    }
                  }
                  newCall.startOfCall = moment.utc().format().toString();
                  newCall.setIncomingCall(true);
                  const activeConference =
                    !isNullOrUndefined(this.rootStore.conversationStore) &&
                    this.rootStore.uiStore.IsOnVideoConference;
                  const secondIncomingTone = newCall.secondIncomingTone;
                  if (presenceStatus !== 'DoNotDisturb' && newCall?.session) {
                    this.setIncomingCalls(newCall);
                    if (this.phoneCalls.length < 1 && !activeConference) {
                      try {
                        await newCall.incomingTone.play();
                      } catch (error) {
                        console.warn('Unable to play incoming tone.', error);
                        /* Not allowed error is thrown when play() is considered an autoplay
                          and the browser is blocking that until the user interacts with the page.
                          Not supported error is thrown when audio formarts are not supported.
                          Since we only use .mp3 this error will only happen in certain scenarios that we cannot control.
                          Both This errors will be ignored
                           */
                        if (
                          error.name !== 'NotAllowedError' &&
                          error.name !== 'NotSupportedError'
                        ) {
                          bugsnagClient.notify(error, (event) => {
                            event.severity = 'error';
                            event.context = 'incomingCall';
                          });
                        }
                      }
                      secondIncomingTone.pause();
                    } else if (activeConference) {
                      newCall.incomingTone.pause();
                      secondIncomingTone.play();
                      secondIncomingTone.volume = 0;
                    } else {
                      newCall.incomingTone.pause();
                      secondIncomingTone.play();
                      secondIncomingTone.volume = 1;
                      let timeout;
                      secondIncomingTone.ontimeupdate = () => {
                        if (
                          !newCall.isIncomingCall ||
                          this.checkIfOtherCallsAreOnHold()
                        ) {
                          clearTimeout(timeout);
                        }
                      };
                      secondIncomingTone.onended = () => {
                        timeout = setTimeout(() => {
                          secondIncomingTone.currentTime = 0;
                          secondIncomingTone.play();
                        }, 5000);
                      };
                    }
                  }
                })();
              },
            },
          };

          await this.establishTransportConnection(configuration);
        }
      }
    );
  }

  checkIfOtherCallsAreOnHold = () => {
    return this.phoneCalls
      .slice(0, -1)
      .every((phoneCall) => phoneCall.isCallOnHold);
  };

  handleTransportEvents = (ua: UserAgent, configuration: UserAgentOptions) => {
    ua.transport.onConnect = () => {
      this.setIsWebRTCConnected(true);
      this.handleRegisterSip();

      this.updateEnableIncomingCalls();

      // clear re-connect timer
      if (this.reconnectTimer) {
        clearTimeout(this.reconnectTimer);
        this.setIsWebRTCReconnecting(false);
      }
    };

    ua.transport.onDisconnect = (error?: Error) => {
      logError(error, 'UA Disconnected', 'PhoneStore', 'onDisconnect', 'info');
      this.setIsWebRTCConnected(false);
      this.setIsWebRTCReconnecting(false);
    };

    this.setSIPKeepAlive();
  };

  /**
   * Establishes a connection to the transport layer using the provided configuration.
   *
   * @param configuration - The configuration object for the UserAgent.
   * @returns The initialized UserAgent instance.
   */
  establishTransportConnection(
    configuration: UserAgentOptions['transportOptions']
  ): void {
    // Create a new UserAgent instance with the provided configuration
    const userAgent = new UserAgent(configuration);

    // Handle transport events for the new UserAgent instance
    this.handleTransportEvents(userAgent, configuration);

    userAgent.start().catch((error) => {
      const currentError = error as Error;
      logError(
        currentError,
        currentError.message,
        'PhoneStore',
        'establishTransportConnection',
        'error'
      );
    });

    this.ua = userAgent;
  }

  @observable
  enableIncomingCalls = true;

  @action
  setEnableIncomingCalls = (enableIncomingCalls: boolean) => {
    this.enableIncomingCalls = enableIncomingCalls;
    this.onToggleOnlyIncomingCalls(enableIncomingCalls);
    localforage.setItem<boolean>(SHOW_INCOMING_CALL, enableIncomingCalls);
  };

  updateEnableIncomingCalls = () => {
    localforage.getItem<boolean>(SHOW_INCOMING_CALL).then((isIncomingCall) => {
      runInAction(() => {
        if (!isNullOrUndefined(isIncomingCall)) {
          this.setEnableIncomingCalls(isIncomingCall);
        } else {
          this.setEnableIncomingCalls(true);
        }
      });
    });
  };

  onToggleOnlyIncomingCalls = (enableIncomingCalls: boolean) => {
    if (!this.registerer) {
      return;
    }
    if (
      enableIncomingCalls &&
      this.registerer.state !== RegistererState.Registered
    ) {
      this.registerer.register();
    } else if (
      !enableIncomingCalls &&
      this.registerer.state === RegistererState.Registered
    ) {
      this.registerer.unregister();
    }
  };

  reconnectSip = () => this.ua.transport.connect();

  @observable
  isWebrtcAttemptDone = false;

  @action
  setIsWebrtcAttemptDone = (isWebrtcAttemptDone: boolean) => {
    this.isWebrtcAttemptDone = isWebrtcAttemptDone;
  };

  private addIncomingCallWebNotification = (
    phoneNumber: string,
    displayName: string
  ) => {
    console.debug('addIncomingCallWebNotification', phoneNumber, displayName);
    const displayNameIsNumber = PhoneNumberUtil.isPossibleNumberString(
      displayName,
      'US'
    );

    this.rootStore.notificationStore.addWebNotification(
      'New incoming call!',
      {
        tag: WN_INCOMING_CALL_PREFIX + phoneNumber,
        requireInteraction: false,
        timeout: 30000,
        icon: !IS_ELECTRON ? PHONE_INCOMING_128 : PHONE_INCOMING_128_S3, // For Electron, use a publicly available URL for Windows desktop notifications
        silent: true,
        body: `${!displayNameIsNumber ? displayName + '\r\n' : ''}${
          !displayNameIsNumber ? phoneNumber : displayName
        }`,
      },
      '',
      'info',
      false
    );
  };

  @observable sipKeepAlive: ReturnType<typeof setInterval> = null;
  @action setSIPKeepAlive = () => {
    this.sipKeepAlive = setInterval(() => {
      if (this.ua.transport.state === 'Disconnected') {
        console.log('SIPKeepAlive: Attempting reconnection.');
        void this.ua.transport.connect();
      }
    }, 15000);
  };

  @observable isAttemptingToReconnectSip = false;
  @action setIsAttemptingToReconnectSip = (
    isAttemptingToReconnectSip: boolean
  ) => {
    this.isAttemptingToReconnectSip = isAttemptingToReconnectSip;
  };

  @action resetTestWebRTCConnected = () => {
    clearInterval(this.setTimer);
    this.setWebrtcCounter(0);
    this.setWebRTCTimer(5);
    this.setIsAttemptingToReconnectSip(false);
  };

  setTimer;
  // This async will garantee to run the Sip registery before making the calls
  testWebRTCConnected = async (): Promise<boolean> =>
    new Promise((resolve) => {
      if (!this.ua) {
        useNotificationStore.getState().addNotify({
          type: 'error',
          title: 'Error starting the call',
          message:
            'It wasn’t possible to start the call. Please refresh the page.',
          dismissible: true,
          action: { label: 'Refresh', onClick: () => location.reload() },
        });

        bugsnagClient.notify(
          'CUU2-2648 User agent is undefined when placing a call.',
          (event) => {
            event.severity = 'warning';
            event.context = 'PhoneStore';
            event.addMetadata('custom', {
              function: 'testWebRTCConnected',
            });
          }
        );

        return resolve(false);
      }

      this.ua.transport.state === 'Disconnected' &&
        void this.ua.transport.connect();

      this.setTimer = setInterval(() => {
        if (this.isWebRTCConnected) {
          this.resetTestWebRTCConnected();
          return resolve(true);
        }

        this.setIsAttemptingToReconnectSip(true);
        this.setWebRTCTimer(this.webRTCTimer - 1);
        if (this.webRTCTimer < 0 && this.webrtcCounter < 6) {
          this.setWebRTCTimer(5);
          this.setWebrtcCounter(this.webrtcCounter + 1);
          this.ua.transport.state === 'Disconnected' &&
            void this.ua.transport.connect();
        }
        if (this.webRTCTimer === 0 && this.webrtcCounter === 6) {
          this.resetTestWebRTCConnected();
          this.setIsWebrtcAttemptDone(true);
          setTimeout(() => this.setIsWebrtcAttemptDone(false), 10000);
        }
      }, 1000);
    });

  /**
   * This function passes the information to `Call` function
   * If the microphone Permission is not there, it wont even make a call
   */
  @action
  callWithPerson = async (
    personId?: number,
    phone?: string,
    isWarmtransfer?: boolean
  ) => {
    await this.refreshMicrophonePermissionStatus('outgoing');
    if (this.browserMicrophonePermissionState !== 'granted') {
      return;
    }

    // we do promise for making sure that if WEBRTC is not Registered Yet, We register it before making a dial. Doing so always guarantee that we wont lose all the information we need to call
    const isWebRTCConnected =
      this.isWebRTCConnected || (await this.testWebRTCConnected());
    if (!isWebRTCConnected) return;

    this.rootStore.uiStore.setOpenedRightSidebarsOrder('dial-pad');

    try {
      /*
      To align behavior accross platforms, directory takes precedence,
      When we don't receive a personId, we check directory for an existing person by phone number
      and return its personId, so we can display the right call destinatary name
      */
      if (personId !== undefined && personId !== null && isFinite(personId)) {
        this.call(
          personId,
          this.rootStore.personStore.loadPersonByIdGetIfMissingGet(personId),
          null,
          null,
          isWarmtransfer,
          null,
          null,
          null
        );
      } else if (phone !== undefined && phone !== null && !isEmpty(phone)) {
        let directoryMatchId: number;

        // If user inserted a phone number, search the directory for a user with that number
        if (isPhoneNumber(phone)) {
          const directoryMatch =
            await this.rootStore.personStore.getFromDirectory(phone);

          if (directoryMatch) {
            directoryMatchId = Number.parseInt(directoryMatch.id);
          }
        } // If not (we assume it's an extension), search the directory for a user with that extension
        else {
          const domain = (await this.rootStore.configStore.signedInPersonConfig)
            ?.data?.webRtc?.domain;

          if (domain) {
            const extensionMatch = await searchPersonBySip(
              `${phone}@${domain}`,
              this.rootStore.notificationStore
            );

            if (extensionMatch) {
              directoryMatchId = Number.parseInt(extensionMatch.id);
            }
          }
        }

        let contact: IPromiseBasedObservable<AxiosResponseT<ContactModel>> =
          null;

        if (
          !directoryMatchId &&
          !phone.startsWith('c/') &&
          !phone.startsWith('Tran') &&
          !/[*ABCD]/g.test(phone)
        ) {
          contact = this.rootStore.contactStore.loadContactByPhoneNumber(phone);
        }

        this.call(
          null,
          // Get user info from the directory or sip search above
          directoryMatchId
            ? this.rootStore.personStore.loadPersonByIdGetIfMissingGet(
                directoryMatchId
              )
            : null,
          phone,
          contact,
          isWarmtransfer,
          null,
          null,
          null
        );
      } else {
        throw new Error('Either Phone Number or Person Id is required.');
      }
    } catch (e) {
      if (e.message === 'Either Phone Number or Person Id is required.') {
        logError(e, '', 'PhoneStore', 'callWithPerson');
        throw e;
      }
    }
  };

  /**
   * Builds the actual sip information and passes all of the info to the PhoneCallModel
   */
  @action
  public call(
    personId?: number,
    person?: IPromiseBasedObservable<AxiosResponseT<PersonModel>>,
    phoneNumber?: string,
    contact?: IPromiseBasedObservable<AxiosResponseT<ContactModel>>,
    isWarmtransfer?: boolean,
    isMerge?: boolean,
    conferenceId?: string,
    callback?: Function
  ) {
    const loggedInPerson = this.rootStore.personStore.selectPersonValueById(
      this.rootStore.personStore.loggedInPersonId
    );
    let loggedInCallerId: string;
    for (let i = 0; i < loggedInPerson.data.lines.length; i++) {
      if (
        loggedInPerson.data.lines[i].id === loggedInPerson.data.primaryLineId
      ) {
        loggedInCallerId = loggedInPerson.data.primaryLineId.toString();
      }
    }
    if (isEmpty(loggedInCallerId)) {
      loggedInCallerId = 'Unknown Number';
    }

    // Check make sure it has atleast one of them available
    if (
      (!personId && isEmpty(phoneNumber)) ||
      (personId && !isEmpty(phoneNumber))
    ) {
      throw new Error('Call requires either personId or phone, but not both.');
    }
    let phoneUriPrefix;
    if (personId) {
      phoneUriPrefix = 'pr' + personId;
    } else if (isMerge) {
      phoneUriPrefix = phoneNumber;
    } else {
      phoneUriPrefix = phoneNumber.replace(/[() -]/g, '');
    }
    if (this.rootStore.configStore.signedInPersonConfig.state === 'fulfilled') {
      const uri = phoneUriPrefix.includes('sip:')
        ? phoneUriPrefix
        : 'sip:' +
          phoneUriPrefix +
          '@' +
          this.rootStore.configStore.signedInPersonConfig.value.data.webRtc
            .domain;
      const completeUri = UserAgent.makeURI(uri.replace(/\s/g, ''));
      const inviter = new Inviter(this.ua, completeUri, { earlyMedia: true });
      const newOutBoundCall = new PhoneCallModel(
        this.rootStore,
        this,
        loggedInCallerId,
        this.rootStore.personStore.loggedInAccountId,
        this.rootStore.personStore.loggedInPersonId,
        inviter,
        completeUri,
        person,
        contact,
        this.localRemoteAudio,
        this.localRingTone,
        this.localIncomingTone,
        this.secondLocalIncomingtone,
        isWarmtransfer,
        isMerge,
        conferenceId,
        this.handleAccept,
        callback
      );
      newOutBoundCall.startOfCall = moment.utc().format().toString();
      newOutBoundCall.setIncomingCall(false);
      newOutBoundCall.setPerson(person);
      newOutBoundCall.setContact(contact);
      newOutBoundCall.setPhoneNumber(phoneNumber);
      this.setPhoneCalls(newOutBoundCall);
      if (this.phoneCalls.length > 1) {
        // Localy keeping track of the newest session
        this.setOtherSessionsOnHold(inviter);
      }
      return newOutBoundCall.session;
    }
  }

  handleAccept = () => {
    this.configureMicrophone(this.microphoneId);
    this.configureSpeaker(this.speakerId);
  };

  public getUserMediaFailure = (e) =>
    logError(e, 'getUserMedia failed:', 'PhoneStore', 'getUserMediaFailure');

  @action
  public guidGenerator = () => uuid();

  @observable
  public numberOfPeopleInARoom: number;

  @action
  public setNumberOfPeopleInARoom = (numberOfPeopleInARoom: number) =>
    (this.numberOfPeopleInARoom = numberOfPeopleInARoom);

  public cRoom = 'c/room';
  @action
  transferToConference = () => {
    let i = 0;
    let guid;
    if (!this.AnyPhoneCallHasConferenceId) {
      guid = this.guidGenerator();
      this.conferenceRoomsIdAndNumbers.set(guid, observable.array());
    }
    let number: string;
    this.rootStore.configStore.signedInPersonConfig.case({
      fulfilled: (resp) => {
        if (this.AnyPhoneCallHasConferenceId && this.ConferenceIdGuid) {
          this.ActivePhoneCall.conferenceId = this.ConferenceIdGuid;

          this.pushIdOrNumberIntoConferenceRoom(this.ActivePhoneCall.callUri);
          const url = `sip:${this.cRoom}${this.ConferenceIdGuid}/noentersound=true,nomoh=true@${resp.data.webRtc.domain}`;
          const uri = UserAgent.makeURI(url.replace(/\s/g, ''));
          this.ActivePhoneCall.session.refer(uri);
          return;
        }
        this.call(
          null,
          null,
          `sip:${this.cRoom}${guid}/noentersound=true,nomoh=true,mintwo=false,endconf=true@${resp.data.webRtc.domain}`,
          null,
          false,
          true,
          guid,
          (phoneCalls) => {
            phoneCalls
              .filter((p) => !p.callUri?.includes(this.cRoom))
              .forEach((element) => {
                if (!element?.callUri?.includes('pr')) {
                  number = element?.callUri?.split('@')[0];
                  if (number.includes('sip:')) {
                    const callUriArray = number.split('sip:');
                    number = callUriArray[callUriArray.length - 1];
                  }
                  element.setMergeTransfer(true);
                  this.pushIdOrNumberIntoConferenceRoom(number);
                } else if (element?.callUri?.includes('pr')) {
                  element.setMergeTransfer(true);
                  const callUri = element?.callUri?.includes('sip:')
                    ? element?.callUri?.split('sip:')[1]
                    : element?.callUri;
                  this.pushIdOrNumberIntoConferenceRoom(callUri);
                  i++;
                }
                element.conferenceId = this.ConferenceIdGuid;
                const url = `sip:${this.cRoom}${this.ConferenceIdGuid}/noentersound=true,nomoh=true@${resp.data.webRtc.domain}`;
                const uri = UserAgent.makeURI(url.replace(/\s/g, ''));
                element.session.refer(uri);
              });
          }
        );
      },
    });
  };

  @action
  pushIdOrNumberIntoConferenceRoom = (id: string) => {
    this.conferenceRoomsIdAndNumbers.get(this.ConferenceIdGuid)?.push(id);
  };

  selectPeopleOrContactsFromConferenceRoom = createTransformer(
    (conferenceId: string) => {
      let number;
      return this.conferenceRoomsIdAndNumbers
        .get(conferenceId)
        .map((value, key) => {
          if (value.includes('pr')) {
            number = value.split('pr').pop().split('@')[0];
            const loadPersonPbo =
              this.rootStore.personStore.loadPersonByIdGetIfMissingGet(number);
            return loadPersonPbo;
          } else {
            number = value.split('@')[0];
            if (!number) return null;
            const loadContactPbo =
              this.rootStore.contactStore.loadContactByPhoneNumber(number);
            return loadContactPbo;
          }
        });
    }
  );

  @computed
  get AnyPhoneConnectionActive() {
    return (
      this.phoneCalls.some((p) => this.isPhoneConnectionActive(p)) ||
      this.incomingPhoneCalls.some((p) => this.isPhoneConnectionActive(p))
    );
  }

  refreshMicrophonePermissionStatus = async (
    callType: null | 'incoming' | 'outgoing'
  ) => {
    if (!IS_ELECTRON && navigator?.permissions) {
      if (navigator.mediaDevices?.getUserMedia) {
        try {
          const localStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          this.setBrowserMicrophonePermissionState('granted');
          //@ts-ignore
          window.localStream = localStream;
          stopMediaStream(window.localStream);
          this.checkForIODevices();
          return;
        } catch (err) {
          this.setBrowserMicrophonePermissionState('denied');
          logError(
            err,
            err.message,
            'PhoneStore',
            'browserMicrophonePermissionState'
          );
          if (callType) {
            const microphoneNotificationMessageCopy = {
              incoming: {
                title: 'Allow access to your microphone',
                description:
                  'To receive calls, enable microphone access in your browser settings.',
              },
              outgoing: {
                title: 'Allow access to your microphone',
                description:
                  'To make calls, enable microphone access in your browser settings.',
              },
              default: {
                title:
                  'To make and receive calls, enable microphone access in your browser settings.',
                description: '',
              },
            }[callType || 'default'];
            this.rootStore.notificationStore.addNotification(
              microphoneNotificationMessageCopy.description,
              microphoneNotificationMessageCopy.title,
              'error'
            );
          }
          return;
        }
      } else {
        console.warn('getUserMedia not supported');
        this.setBrowserMicrophonePermissionState('denied');
        return;
      }
    } else {
      // must assume granted since we can't query :(, when we upgrade sip we can do better
      // [BC-646] Electron environments always sink granted
      this.setBrowserMicrophonePermissionState('granted');
      return;
    }
  };

  isPhoneConnectionActive = (phoneCall) =>
    phoneCall.isCallConnecting ||
    phoneCall.isCallConnected ||
    phoneCall.isIncomingCall ||
    phoneCall.isMediaConnecting;

  /**
   *  The call that is active and NOT on HOLD
   *
   *  returns `undefined` if nothing is found
   */
  @computed
  get ActivePhoneCall() {
    // The newest Session , isTransferMode set to false
    return this.phoneCalls[this.phoneCalls.length - 1];
  }

  @computed
  get IsActiveCall() {
    return (
      this.AnyPhoneConnectionActive ||
      this.isTenSeconds ||
      this.rootStore.conversationStore.listOfIncomingVideoCalls.size > 0
    );
  }

  @action
  cancelCallOnNoWebRTC = () => {
    this.setIsAttemptingToReconnectSip(false);
    this.setIsWebrtcAttemptDone(false);
    this.setWebrtcCounter(0);
    this.setWebRTCTimer(5);
    clearInterval(this.setTimer);
    this.incomingPhoneCalls.forEach((element) => element.cancel());
    this.phoneCalls.forEach((element) => element.cancel());
    this.phoneCalls = observable.array();
    this.incomingPhoneCalls = observable.array();
  };

  @observable
  isInternetConnectedOnActiveCall = true;

  @action
  setIsInternetConnectedOnActiveCall = (
    isInternetConnectedOnActiveCall: boolean
  ) => (this.isInternetConnectedOnActiveCall = isInternetConnectedOnActiveCall);

  @observable
  isTenSeconds = false;

  @action
  setIsTenSeconds = (isTenSecondsOver: boolean) =>
    (this.isTenSeconds = isTenSecondsOver);

  @observable
  setTimeOut: any;

  @action
  noInternetOnActiveCall = () => {
    this.setIsWebRTCReconnecting(true);
    this.setTimeOut = setTimeout(() => {
      this.setIsTenSeconds(true);
      this.setIsWebRTCReconnecting(false);
      this.cancelCallOnNoWebRTC();
    }, 10000);
  };

  @action
  cancelBeforeTenSeconds = () => {
    clearTimeout(this.setTimeOut);
    this.setIsTenSeconds(false);
    this.setIsWebRTCReconnecting(false);
    this.cancelCallOnNoWebRTC();
  };

  @computed
  get AnyPhoneCallHasConferenceId() {
    return (
      this.phoneCalls.filter((p) => p.callUri?.includes(this.cRoom)).length > 0
    );
  }

  @computed
  get ConferenceIdGuid() {
    if (this.AnyPhoneCallHasConferenceId) {
      return this.phoneCalls.filter((p) => p.callUri?.includes(this.cRoom))[0]
        .conferenceId;
    } else {
      return null;
    }
  }

  /**
   * The list Of calls that are active and  on HOLD
   *
   * returns Empty Array if nothing is found
   */
  @computed
  get PhoneCallsOnHold() {
    return this.phoneCalls.filter((item) => {
      if (item.isCallConnected && item.isCallOnHold) {
        return item;
      }
    });
  }

  @computed
  get TransferMode() {
    return (
      this.phoneCalls.filter((item) => item.transferMode === true).length > 0
    );
  }

  /**
   * return the item that is in hold for transfer
   */
  @computed
  get callBeingTransfered() {
    return this.phoneCalls.find((item) => item.transferMode);
  }

  @action
  removeFromPhoneCallsArray = (s: Session) => {
    this.phoneCalls.forEach((item) => {
      if (item.session.id === s.id) {
        runInAction(() => this.phoneCalls.remove(item));
      }
    });
  };

  /**
   * The function will Loop through all the sessions in the array and put them on hold
   */
  @action
  setOtherSessionsOnHold = (activeSession: Session) => {
    this.phoneCalls.forEach((item) => {
      if (
        item.session.id !== activeSession.id &&
        item.session.state === 'Established'
      ) {
        void item.hold();
      }
    });
  };

  @action
  makeCallActive = (session: Session) => {
    const index = this.phoneCalls.findIndex(
      (item) => item.session.id === session.id
    );
    this.phoneCalls.push(this.phoneCalls.splice(index, 1)[0]);
  };

  /**
   * The list of calls connecting (Incoming or outgoing)
   */
  @computed
  get InactiveCalls() {
    return this.phoneCalls.filter((item) => item.isCallConnecting);
  }
}
