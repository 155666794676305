import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'Constants/env';
import { AxiosResponseT, ResultsCollection } from 'Interfaces/axiosResponse';
import { MobXProviderContext } from 'mobx-react';
import ConversationModel, {
  IConversationModel,
} from 'Models/ConversationModel';
import PersonModel from 'Models/PersonModel';
import React from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { isNullOrUndefined } from 'util';
import API from '~/api';
import { useConversationStore } from './index.store';
import { OptOutStatusResponse } from './index.types';

export const QUERY_KEY_FETCH_OPT_OUT_STATUS = 'conversation.fetchOptOutStatus';
export const QUERY_KEY_FETCH_PERSON_BY_PERSON_ID =
  'conversation.fetchPersonByPersonId';
export const QUERY_KEY_FETCH_CONVERSATIONS = 'conversation.fetchConversations';
export const QUERY_KEY_FETCH_CONVERSATION = 'conversation.fetchConversation';

/*FIXME: For now we need to export this, so we can call on the mobx store (class instance)
 * We will also be able to rely on "useQueries" to parallelize the requests, instead of the Promise.allSettled
 */
export const fetchOptOutStatus = async (
  phoneNumbers: string[]
): Promise<Map<string, boolean>> => {
  if (phoneNumbers.length === 0) {
    return useConversationStore.getState().optOutPhoneNumbersInfo;
  }

  const { data: optOutStatusResponse } = await API.get<OptOutStatusResponse>(
    API_ENDPOINTS.OptOutStatus(),
    {
      params: { phoneNumbers },
    }
  );

  /**
   * The endpoint may return an empty array, or simply not include all the requested phone numbers.
   * In those cases, we will assume the number is not opted out.
   */
  phoneNumbers.forEach((phoneNumber) => {
    const optOutInfo = optOutStatusResponse.find(
      (contact) => contact.phoneNumber === phoneNumber
    );

    useConversationStore
      .getState()
      .updateOptOutPhoneNumberInfo(
        phoneNumber,
        isNullOrUndefined(optOutInfo) ? false : optOutInfo.optedOut
      );
  });

  return useConversationStore.getState().optOutPhoneNumbersInfo;
};

const fetchMentionUsers = async (
  personIds: number[]
): Promise<Map<number, PersonModel>> => {
  if (isNullOrUndefined(personIds) || personIds.length === 0) {
    return useConversationStore.getState().mentionUsers;
  }

  const fetchPromises = personIds.map(async (personId) => {
    const { data } = await API.get<PersonModel>(
      API_ENDPOINTS.PeoplePersonById(personId)
    );

    useConversationStore
      .getState()
      .addMentionUser(PersonModel.FromResponseDto(data));
  });

  await Promise.all(fetchPromises);

  return useConversationStore.getState().mentionUsers;
};

export const fetchConversations = async (
  showCallMessagesInChat: boolean
): Promise<AxiosResponseT<ResultsCollection<IConversationModel>>> => {
  const conversationsResponse = await API.get<
    ResultsCollection<IConversationModel>
  >(
    API_ENDPOINTS.ConversationsQuery({
      Limit: 100,
      IncludeRemoved: true,
      ShowCallMessagesInChat: showCallMessagesInChat,
    })
  );

  return conversationsResponse;
};

export const fetchConversation = async (
  conversationId: string
): Promise<AxiosResponseT<ConversationModel>> => {
  const conversationsResponse = await API.get<ConversationModel>(
    API_ENDPOINTS.ConversationById(conversationId)
  );

  return conversationsResponse;
};

export const useFetchOptOutStatus = (phoneNumbers: string[]) =>
  useQuery({
    queryKey: [QUERY_KEY_FETCH_OPT_OUT_STATUS, phoneNumbers],
    queryFn: async () => fetchOptOutStatus(phoneNumbers),
  });

export const useFetchMentionUsers = (personIds: number[]) =>
  useQuery({
    queryKey: [QUERY_KEY_FETCH_PERSON_BY_PERSON_ID, personIds],
    queryFn: async () => fetchMentionUsers(personIds),
  });

export const useFetchConversations = (
  enabled: boolean,
  refetchInterval: number | (() => number)
) => {
  const {
    conversation: { loadConversationsGetSuccess },
    preference: {
      preferences: { showCallMessagesInChat },
    },
    router: { location },
    ui: { activeSidebarItem },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const locationPath = location.pathname.split('/')?.[1] || '';

  return useQuery({
    queryKey: [
      QUERY_KEY_FETCH_CONVERSATIONS,
      enabled,
      refetchInterval,
      !!showCallMessagesInChat,
    ],
    queryFn: async () => {
      const conversations = await fetchConversations(!!showCallMessagesInChat);

      loadConversationsGetSuccess(conversations);

      return conversations;
    },
    enabled:
      enabled && locationPath === 'chat' && activeSidebarItem === 'recent',
    refetchInterval,
    staleTime: 0,
  });
};

export const useFetchConversation = (
  enabled: boolean,
  conversationId?: string
) => {
  const {
    conversation: { loadSingleConversationSuccess },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  return useQuery({
    queryKey: [QUERY_KEY_FETCH_CONVERSATION, enabled, conversationId],
    queryFn: async () => {
      const conversation = await fetchConversation(conversationId);

      loadSingleConversationSuccess(conversation);

      return conversation;
    },
    enabled: enabled && !!conversationId,
    staleTime: 0,
    refetchOnWindowFocus: false,
  });
};

export const useConversation = () => ({
  useFetchOptOutStatus,
  useFetchMentionUsers,
  useFetchConversations,
});
