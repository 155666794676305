/** localforage key for IPersonSignInResponse values (PersonStore) */
export const SIGN_IN_RESPONSE = 'sign_in_response';

export const STORE_CHAT_HTML = 'store_chat_html';
export const STORE_CHAT_RAW = 'store_chat_raw';

export const SELECTED_MICROPHONE = 'selected_microphone';
export const SHOW_INCOMING_CALL = 'show_incoming_call';

export const SELECTED_SPEAKER = 'selected_speaker';
export const SELECTED_RING_DEVICE = 'selected_ringing_speaker';

export const WEB_NOTIFICATIONS_ENABLED = 'web_notifs_enabled';
export const WEB_NOTIFICATION_ACTION_TAKEN = 'web_notif_action_taken';

export const ACTIVITY_LIST_ITEMS_COLLAPSED = 'activity_list_items_collapsed';

export const ACTIVE_CALL = 'active_call';
export const RECENT_CALLS = 'recent_calls';

export const SOFTPHONE_DIMENSIONS_POSITION = 'softphone_dimensions_position';
