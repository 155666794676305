import { MobXProviderContext, observer } from 'mobx-react';
import { CallQualitySurveyPayload } from 'Modules/survey/index.types';
import { useCallSurveyStore } from 'Modules/survey/store';
import React, { useEffect, useCallback } from 'react';
import { startCallQualitySurvey } from 'Utils/intercomWrapper';
import { RootStoreProps } from '../../stores/RootStore.types';

/**
 * SurveyProvider Component
 *
 * Handles the presentation of Intercom surveys after call completion.
 * Listens for survey threshold events and manages the survey display logic.
 *
 * Features:
 * - Shows survey after 5th answered call
 * - Only shows once per day
 * - Works with both test and production environments
 * - Handles survey state persistence
 *
 * @param {React.PropsWithChildren} props - React children components
 * @returns {JSX.Element} Provider component that wraps the application
 */
export const SurveyProvider: React.FC<React.PropsWithChildren> = ({
  children,
}: React.PropsWithChildren): JSX.Element => {
  const {
    person: { loggedInPersonId },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const personId = loggedInPersonId.toString();

  const { markSurveyShown } = useCallSurveyStore(personId).getState();
  /**
   * Handle survey threshold
   */
  const handleSurveyThreshold = useCallback(
    async (event: CustomEvent<CallQualitySurveyPayload>) => {
      const { callId, callStartTime, callEndTime } = event.detail;

      try {
        // Start the survey
        await startCallQualitySurvey({
          callId,
          callStartTime,
          callEndTime,
        });

        // Mark the survey as shown
        markSurveyShown(callId);
      } catch (error) {
        console.error('Error showing survey:', error);
      }
    },
    [markSurveyShown]
  );

  useEffect(() => {
    const eventListener = handleSurveyThreshold as EventListener;

    window.addEventListener('surveyThresholdReached', eventListener);

    return () => {
      window.removeEventListener('surveyThresholdReached', eventListener);
    };
  }, [handleSurveyThreshold]);

  return <>{children}</>;
};

export default observer(SurveyProvider);
