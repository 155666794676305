import { isEmpty } from 'lodash';
import { IS_ELECTRON } from '../constants/env';
import { IUnreadCountsResult } from '../interfaces/components';
import { IKeytarCredentials } from '../interfaces/ipcMessages';
import {
  IWindowsToastImageOptions,
  WindowsToastAudioOption,
  WindowsToastTemplateType,
} from '../interfaces/notifications';
import { EventResponse } from '../models/Calendar';
import {
  ParticipantStore,
  PersonStore,
  PreferenceStore,
  RouterStore,
  UiStore,
} from '../stores';

import { CalendarStore } from '../stores/CalendarStore';
import { pushToGTMDataLayer } from './analytics';

export type ElectronLogLevels =
  | 'error'
  | 'warn'
  | 'info'
  | 'verbose'
  | 'debug'
  | 'silly';
/**
 * Send a `log` message via `ipcRenderer`
 * @param level The log level
 * @param message Main message to send
 * @param rest Additional arguments to spread after the main ones
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcLog(
  level: ElectronLogLevels,
  message: any,
  ...rest: any[]
) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send('log', level, message, ...rest);
    return true;
  }
  return false;
}

/**
 * Send a `click-notification` message via `ipcRenderer`
 * @param tag The `tag` of the notification that was clicked
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcClickNotification(tag: string) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send('click-notification', tag);
    return true;
  }
  return false;
}

/**
 * Send a `new-message` message via `ipcRenderer`
 *
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcNewMessage() {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send('new-message');
    return true;
  }
  return false;
}

/**
 * Send a `request-open-on-login` message via `ipcRenderer`.
 * `ipcMain` will async send an `open-on-login` event, which should be handled in this file as well.
 *
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcRequestOpenOnLogin() {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send('request-open-on-login');
    return true;
  }
  return false;
}

/**
 * Send a `set-open-on-login` message via `ipcRenderer`.
 * `ipcMain` will async send an `open-on-login` event, which should be handled in this file as well.
 *
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcSetOpenOnLogin(openOnLogin: boolean) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send('set-open-on-login', openOnLogin);
    return true;
  }
  return false;
}

/**
 * Send a `open-video` message via `ipcRenderer`
 * @param confUrl The URL of the Conference to load.
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcOpenVideo(confUrl: string) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send('open-video', confUrl);
    return true;
  }
  return false;
}

/**
 * Send a `copy-to-clipboard` message via `ipcRenderer`
 * @param content The content to copy to the clipboard
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcCopyToClipboard(content: string) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send('copy-to-clipboard', content);
    return true;
  }
  return false;
}

/**
 * Send a `native-toast` message via `ipcRenderer`
 *
 * @export
 * @param e
 * @param textLines
 * @param launch
 * @param [toastAppLogoImageOptions]
 * @param [bindingTemplate='ToastGeneric']
 * @param [actionsElement]
 * @param [commandsElement]
 * @param [enableAudio=false]
 * @param [audioOption='Notification.IM']
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcNativeToast(
  conversationId: string,
  messageId: string,
  eventId: number,
  textLines: string[],
  launch: string,
  toastAppLogoImageOptions?: IWindowsToastImageOptions,
  bindingTemplate: WindowsToastTemplateType = 'ToastGeneric',
  actionsElement?: string,
  enableAudio = false,
  audioOption: WindowsToastAudioOption = 'Notification.IM'
) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send(
      'native-toast',
      conversationId,
      messageId,
      eventId,
      textLines,
      launch,
      toastAppLogoImageOptions,
      bindingTemplate,
      actionsElement,
      enableAudio,
      audioOption
    );
    return true;
  }
  return false;
}

/**
 * Send a `request-saved-credentials` message via `ipcRenderer`.
 * `ipcMain` will async send a `saved-credentials` event, which should be handled in this file as well.
 *
 * @param account The login email for the user
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcRequestSavedCredentials() {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send('request-saved-credentials');
    return true;
  }
  return false;
}

/**
 * Listen to ipcRenderer channel `saved-credentials`.
 * On receipt, set the `PersonStore` `loginPage*` values.
 */
export function handleSavedCredentials(personStore: PersonStore) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.on(
      'saved-credentials',
      (e, credentials: IKeytarCredentials[]) => {
        if (
          !isEmpty(credentials) &&
          !isEmpty(credentials[0]?.account) &&
          !isEmpty(credentials[0]?.password)
        ) {
          personStore.setIpcCredentialsEmail(credentials[0].account);
          personStore.setIpcCredentialsPassword(credentials[0].password);
        }
      }
    );
    return true;
  }
  return false;
}

/**
 * Listen to ipcRenderer channel `navigate-to`. On receipt, set the window location to `url`
 */
export function handleNavigateTo(routerStore: RouterStore) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.on('navigate-to', (e, url) => {
      // Prevents weird error where clicking on a renderer (web) notification for some reason gets sent a broken navigate-to url which creates a blank screen (RP 2019-11-18)
      if (!url.includes('&userData=')) {
        console.debug('ipcRenderer received navigate-to', url);
        routerStore.push(url);
      } else {
        console.debug(`ipcRenderer prevented invalid navigate-to: ${url}`);
      }
    });
    return true;
  }
  return false;
}

export function handleEventResponse(calendarStore: CalendarStore) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.on('event-response', (e, eventId, index) => {
      const responses = ['yes', 'no'];
      if (responses[index]) {
        calendarStore.handleEventRespond(
          responses[index] as EventResponse,
          eventId
        );
      }
    });
    return true;
  }
  return false;
}

/**
 * Listen to ipcRenderer channel `open-on-login`.
 * On receipt, set the `PreferenceStore` `openOnLogin` value.
 */
export function handleOpenOnLogin(preferenceStore: PreferenceStore) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.on(
      'open-on-login',
      (e, openOnLogin: boolean) => {
        console.debug('ipcRenderer received open-on-login', openOnLogin);
        void preferenceStore.setDesktopLocalPreferencesData({ openOnLogin });
      }
    );
    return true;
  }
  return false;
}

/**
 * Listen to ipcRenderer channel `mark-read`. On receipt, mark the Message in the Conversation provided as read
 */
export function handleMarkRead(
  participantStore: ParticipantStore,
  uiStore: UiStore
) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.on(
      'mark-read',
      (e, conversationId, messageId) => {
        console.debug(
          'ipcRenderer received mark-read',
          conversationId,
          messageId
        );
        if (!isEmpty(messageId)) {
          void participantStore
            .updateMyLastReadMessage(conversationId, messageId)
            .then(() => {
              uiStore.setConversationAndTotalUnreadCount(
                conversationId,
                0,
                null,
                0
              );
            })
            .catch(error => {
              console.error('Error updating last read message:', error);
            });
        }
      }
    );
    return true;
  }
  return false;
}

/**
 * Send an `identify` message via `ipcRenderer`
 * @param uid The unique identifier for the user (`accountId:personId`).
 * @param name The full name of the user being identified.
 * @param email The email of the user being identified
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcIdentify(
  accountId: string,
  personId: string,
  name: string,
  email: string,
  packageName: string,
  locationId: string
) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send(
      'identify',
      accountId,
      personId,
      name,
      email,
      packageName,
      locationId
    );
    console.debug(
      `ipcRenderer sent identify for ${accountId}:${personId}: ${name} <${email}>`
    );
    return true;
  }
  return false;
}

/**
 * Send a `update-badge` message via `ipcRenderer`. This is used to `setBadgeCount` on Mac, and on Windows, to set the count for `electron-windows-badge`
 *
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcUnreadCounts(unreadCounts: IUnreadCountsResult) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    const unreadMsgCount = unreadCounts.totalUnreadCounts.unreadMessages || 0;
    window.ipcRenderer.send(
      'update-badge',
      unreadMsgCount > 0 ? unreadMsgCount : null
    );
    console.debug('ipcRenderer sent update-badge', unreadMsgCount);
    return true;
  }
  return false;
}

/**
 * Send a `save-credentials` message via `ipcRenderer`, which will cause `keytar` to persist credentials.
 *
 * @param email The email to be saved
 * @param password The password to be saved
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcSaveCredentials(email: string, password: string) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send('save-credentials', email, password);
    console.debug(`ipcRenderer sent save-credentials for ${email}`);
    return true;
  }
  return false;
}

/**
 * Send a `login-source` message via `ipcRenderer`, which will cause `keytar` to persist credentials.
 *
 * @param email The email to be saved
 * @param password The password to be saved
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcLoginUrl(SOURCE_CONFIG_URL: string) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send('login-source', SOURCE_CONFIG_URL);
    console.debug(`ipcRenderer sent login-source`);
    return true;
  }
  return false;
}

/**
 * Listen to ipcRenderer channel `login-source-code-received`.
 */
export function handleCodeReceived(cb: (code: string, url: string) => void) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.on(
      'login-source-code-received',
      (e, code: string, url: string) => {
        cb(code, url);
        window.ipcRenderer.removeAllListeners(
          'login-source-code-received'
        );
      }
    );
    return true;
  }
  return false;
}

/**
 * Send a `login-auth0` message via `ipcRenderer`, which will cause `keytar` to persist credentials.
 *
 * @param email The email to be saved
 * @param password The password to be saved
 * @export
 * @returns True if the message was sent (user is in Electron env and `ipcRenderer` exists on `window`)
 */
export function sendIpcAuth0LoginUrl(SOURCE_CONFIG_URL: string) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send('login-auth0', SOURCE_CONFIG_URL);
    console.debug(`ipcRenderer sent login-auth0`);
    return true;
  }
  return false;
}

/**
 * Listen to ipcRenderer channel `login-auth0-code-received`.
 */
export function handleAuth0CodeReceived(
  cb: (code: string, url: string) => Promise<void>
) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.on(
      'login-auth0-code-received',
      (e, code: string, url: string) => {
        void cb(code, url);
      }
    );
    return true;
  }
  return false;
}

/**
 * Listen to ipcRenderer channel `login-auth0-close`. This event is triggered when the oaith window closes.
 */
export function handleAuth0WindowClose(callback: () => void) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.on('login-auth0-close', () => {
      callback();
    });
    return true;
  }
  return false;
}

/**
 * Send a `focus-auth0` message via `ipcRenderer`, which will cause auth0 window to be focused on.
 */
export function focusAuth0Window() {
  if (IS_ELECTRON && window?.ipcRenderer) {
    window.ipcRenderer.send('focus-auth0');
    console.debug(`ipcRenderer sent focus-auth0`);
    return true;
  }
  return false;
}

/**
 * Opens a link with `url` in the OS Native browser, if in Electron.
 *
 * @param url string
 */
export function handleElectronOpenExternal(url: string) {
  if (IS_ELECTRON && window?.ipcRenderer) {
    if (!isEmpty(url)) {
      window?.ipcRenderer.send('open-external-link', url);

      console.debug(`ipcRenderer sent open-external-link`, url);

      pushToGTMDataLayer('linkClick');
    }
  }
}
