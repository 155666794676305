import AddNewContact from 'Components/AddNewContact';
import { ContactsListWrapper } from 'Components/ContactsListWrapper';
import { SourcesAddressBook } from 'Components/SourcesAddressBook';
import { ADDRESS_BOOK_SUBPATHS } from 'Constants/routing';
import AddressBookContainer from 'Containers/AddressBookContainer';
import React from 'react';
import { Navigate, RouteObject } from 'react-router';

const addressBookRoutes: RouteObject = {
  path: 'addressBook/*',
  element: <AddressBookContainer />,
  handle: { title: 'Address Book' },
  children: [
    { path: 'contact', element: <AddNewContact /> },
    { path: 'contacts', element: <ContactsListWrapper /> },
    {
      path: 'sources',
      element: <SourcesAddressBook titleFor="contacts" />,
    },
    {
      path: '*',
      element: <Navigate to={ADDRESS_BOOK_SUBPATHS.CONTACTS} replace />,
    },
  ],
};

export default addressBookRoutes;
